import Chart from 'chart.js/auto'

document.addEventListener('turbolinks:load', () => {
  if (document.getElementById('payments-ratio') != null) {
    const context = document.getElementById('payments-ratio').getContext('2d');
    console.log(context.canvas.dataset);
    const graph_data = JSON.parse(context.canvas.dataset.paymentsData);
    const color_list = [];
    for (let i in graph_data) {
      if (i == 0) {
        color_list.push("#9A1663")
      } else if (i == 1) {
        color_list.push("#E0144C")
      } else if (i == 2) {
        color_list.push("#FF5858")
      } else if (i == 3) {
        color_list.push("#FF97C1")
      } else {
        color_list.push("#FFDAB9")
      }
    }
    const countPlaceGraph = new Chart(context, {
      type: 'doughnut',
      data: {
        labels: JSON.parse(context.canvas.dataset.labels),
        datasets: [
          {
            label: "",
            backgroundColor: color_list,
            hoverBackgroundColor: "rgb(255, 218, 185)",
            data: JSON.parse(context.canvas.dataset.paymentsData),
          },
        ]
      },
    });
  }
})
